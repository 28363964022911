.gallery-container {
  background-color: #eeeeee;
  border-radius: 25px;
  width: 500px;
  height: 500px;
  margin: 0;
  padding: 0 20px 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: space-between;
  list-style: none;
}

.gallery-item {
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  margin: 20px 0 0 20px;
  flex: 1 1 100px;
}

.overlay {
  background: white;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.single-image-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.single-image-container * {
  pointer-events: all;
}

.single-image {
  border-radius: 20px;
  width: 500px;
  height: 300px;
}
