.project-logo {
  height: 30px;
  width: 30px;
  margin-bottom: 10px;
  margin-right: 10px;
  overflow: hidden;
}
.language {
  color: rgb(108, 117, 125);
}
.language:hover {
  color: #14b8a6;
}
.css-levciy-MuiTablePagination-displayedRows {
  margin: 0;
}
.css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel {
  margin: 0;
}
