.nav {
  margin: 0 10px;
  width: 150px;
  text-align: center;
  text-decoration: none;
  line-height: 49px;
  font-size: 20px;
  color: black;
}
.nav:hover {
  color: #14b8a6;
}
.footer-nav {
  margin: 0 30px;
  color: gray;
  text-decoration: none;
}
.footer-nav:hover {
  color: #14b8a6;
}
@media (max-width: 600px) {
  .footer-nav {
    margin: 0 15px;
  }
  .nav-list {
    margin: 10px 0 0 0;
  }
  .nav {
    width: fit-content;
  }
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.box {
  transition: transform 0.3s ease;
  cursor: pointer;
  background-color: white;
}
.box:hover {
  transform: scale(1.1);
  background-color: #fefae0;
}
